// Custom fonts
const fontsContext = require.context('../fonts/', true, /\.css$/)
fontsContext.keys().forEach(fontsContext)

// Turbo
import * as Turbo from "@hotwired/turbo"

// Stimulus
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Custom Elements
import { Toast } from "custom_elements/toast"
customElements.define('toast-notification', Toast)

import { Aerosol } from "aerosol"
Aerosol.sprinkle()