import { ElementObserver } from "utilities/element_observer"
import { Context } from "./context"

export class Observer {
  static start(sprinkleType) {
    let delegate = new Observer(sprinkleType)

    this.observer = new ElementObserver(document, sprinkleType.selector, delegate)
    this.observer.refresh()
    this.observer.start()
  }

  constructor(sprinkleType) {
    this.sprinkleType = sprinkleType
  }

  selectorMatched(element) {
    if (element.sprinkleContext) {
      element.sprinkleContext.connect()
    } else {
      Context.attach(element, this.sprinkleType).setup()
    }
  }

  matchedSelectorRemoved(element) {
    element.sprinkleContext.disconnect()
  }
}