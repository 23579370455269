export class EventListener {
  constructor(eventName, eventTarget, handlerName) {
    this.eventName = eventName
    this.eventTarget = eventTarget
    this.handlerName = handlerName
  }

  connect() {
    this.eventTarget.addEventListener(this.eventName, this)
  }

  disconnect() {
    this.eventTarget.removeEventListener(this.eventName, this)
  }

  handleEvent(event) {
    this.eventTarget.sprinkleContext.sprinkle[this.handlerName](event)
  }
}