import { Sprinkle } from "../sprinkle"

export default class extends Sprinkle {
  static attribute = "data-confirm"
  static selector  = `[${this.attribute}]`

  static eventListeners = {
    "click": "showConfirmationAlert"
  }

  showConfirmationAlert(event) {
    if (event.overrideConfirmation) { return true }

    event.preventDefault()
    let confirmed = confirm(this.element.getAttribute(this.constructor.attribute))

    if (confirmed) {
      let newEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true
      });
      newEvent.overrideConfirmation = true
      this.element.dispatchEvent(newEvent)
    }
  }
}