import { Observer } from "./observer"

import ConfirmationAlertSprinkle from "./sprinkles/confirmation_alert_sprinkle"
import FormSprinkle from "./sprinkles/form_sprinkle"
import TextAreaSprinkle from "./sprinkles/text_area_sprinkle"

export class Aerosol {
  static sprinkle() {
    [FormSprinkle, ConfirmationAlertSprinkle, TextAreaSprinkle].forEach(sprinkle => {
      Observer.start(sprinkle)
    })
  }
}