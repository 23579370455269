import { EventListener } from "./event_listener"

export class Context {
  static attach(element, sprinkleType) {
    return new Context(element, sprinkleType)
  }

  constructor(element, sprinkleType) {
    this.element = element
    element.sprinkleContext = this
    this.eventListeners = []
    this.sprinkle = new sprinkleType(this)
  }

  setup() {
    this.attachEventListeners()
    this.connect()
  }

  connect() {
    this.eventListeners.forEach(listener => listener.connect())
    this.sprinkle.connect()
  }

  disconnect() {
    this.sprinkle.disconnect()
    this.eventListeners.forEach(listener => listener.disconnect())
  }

  attachEventListeners() {
    Object.entries(this.sprinkle.constructor.eventListeners).forEach(([event, handler]) => {
      this.eventListeners.push(new EventListener(event, this.element, handler))
    })
  }
}