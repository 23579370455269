import { Sprinkle } from "../sprinkle"

export default class extends Sprinkle {
  static selector  = "form"
  static eventListeners = {
    "turbo:submit-start": "disableSubmits",
    "turbo:submit-end": "enableSubmits"
  }

  connect() {
    this.scrollToFirstError()
  }

  enableSubmits() {
    this.submits.forEach(submit => submit.disabled = false)
  }

  disableSubmits() {
    this.submits.forEach(submit => submit.disabled = true)
  }

  scrollToFirstError() {
    let errorElement = this.element.querySelector("[am-state~='error']")
    if (errorElement) {
      errorElement.scrollIntoView({ behaviour: "smooth", block: "center" })
    }
  }

  get submits() {
    return this.element.querySelectorAll("button[type='submit']")
  }
}
