export default class extends ApplicationController {
  static targets = ["checked", "unchecked", "checkbox"]

  connect() {
    this.updateUI()
  }

  toggle() {
    this.updateUI()
  }

  updateUI() {
    if(this.checkboxTarget.checked) {
      this.checkedTarget.removeAttribute("style")
      this.uncheckedTarget.style.display = "none"
    } else {
      this.checkedTarget.style.display = "none"
      this.uncheckedTarget.removeAttribute("style")
    }
  }
}