export class Sprinkle {
  constructor(context) {
    this.context = context
    this.initialize()
  }

  get element() {
    return this.context.element
  }

  initialize() {}
  connect() {}
  disconnect() {}
}