import { Sprinkle } from "../sprinkle"

export default class extends Sprinkle {
  static selector  = "textarea"
  static eventListeners = {
    "input": "autoresize",
  }

  async autoresize() {
    await this.nextFrame()

    if (this.element.dataset.minHeight == null) {
      this.element.dataset.minHeight = this.element.clientHeight
    }

    this.element.style.height = null

    const offset = this.element.offsetHeight - this.element.clientHeight
    const newHeight = this.element.scrollHeight + offset
    if (newHeight > this.element.dataset.minHeight) {
      this.element.style.height = `${this.element.scrollHeight + offset}px`
    }
  }

  nextFrame() {
    return new Promise(requestAnimationFrame)
  }
}